import React from "react";
import "./hero-section.css";
import Lumba from "../../../images/tulumbe/lumba.png";
import LumbaHero from "../../../images/tulumbe/hero-mobile.png";
import PrimaryButton from "./primary-button";

const HeroSection = () => {
  return (
    <>
      <section className="hero-section d-flex align-items-center mrc-desktop">
        <div className="container mrc-desktop">
          <div className="row align-items-center mrc-desktop hero-content">
            <div className="col-lg-6 text-center text-lg-start mrc-desktop">
              <div className="d-flex justify-content-center mrc-desktop">
                <img src={Lumba} alt="Lumba" className="mb-lg-5  w-100 mrc-desktop" />
              </div>
              <div className="text-center mb-lg-5 mrc-desktop">
                <p className="hero-subtitle mrc-desktop">
                  The simplest way to support the Cranes in Morocco.<br/> <span className="highlight-text text-bold">One-click trip planning & AutoSaving</span> for a stress-free AFCON experience!

                </p>
              </div>
              <div className="d-flex justify-content-center mrc-desktop">
                <PrimaryButton href="https://join.myxeno.com/?country=UG&goal=23">Get Started</PrimaryButton>
              </div>

            </div>

            <div className="col-lg-6 text-center mrc-desktop">

            </div>
          </div>
        </div>
      </section>

      <section className="hero-section-mobile d-flex align-items-center mrc-mobile">
        <div className="container mrc-mobile">
          <div className="row align-items-center hero-content">
            <div className="col-lg-6 text-center text-lg-start">
              <div className="d-flex justify-content-center">
                <img src={LumbaHero} alt="Lumba" className="mb-lg-5  w-100" />
              </div>
              <div className="text-center mb-5 pt-5">
                <p className="hero-subtitle">

                  The simplest way to support the Cranes in Morocco. <span className="highlight-text text-bold">One-click trip planning & AutoSaving</span> for a stress-free AFCON experience!
                </p>
              </div>

              <div className="d-flex justify-content-center mb-5 mt-4">
                <PrimaryButton className="primary-button" href="https://join.myxeno.com/?country=UG&goal=23">Get Started</PrimaryButton>
              </div>

            </div>

            <div className="col-lg-6 text-center mrc-mobile">

            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default HeroSection;
