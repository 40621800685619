import React from "react";
import styled from "styled-components";
import PackageTwo from "../../images/tulumbe/package-2.png";
import PackageTwoMobile from "../../images/tulumbe/package-mobile-2.png";
import PackageNumberTwo from "../../images/tulumbe/package-02.png";

import "bootstrap/dist/css/bootstrap.min.css";
import GradientBackground from "./gradient-background"
import GradientButton from "./blocks/gradient-button"


const MoroccoExperienceSection = styled.div`
   display: flex; 
  flex-direction: column;
    align-items: center;
  text-align: center;
  color: white;
    position: relative;
    z-index: 2;
`;


const PackageTitle = styled.h2`
  font-size: 75px;
  font-weight: bold;
  margin-bottom: 1rem;
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    background-clip: text;
    color: white;
    width: 99.6%;
`;

const PackageImageWrapper = styled.div`
    display: flex;
    justify-content: center;

    img {
        width: 100%;
        max-width: 100%;
    }


`;

const ContentWrapper = styled.div`
    margin-top: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    width: 100%;
    .x-font-size-2 {
        font-size: 22px;
    }
`;

const PackageTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100%; 
    text-align: left;
    .x-bottom-padding {
        padding-bottom: 8rem !important;
    }
`;

const ClientMoroccoExperiencePackage2 = () => {


  return (

        <div className="container-fluid text-white px-lg-5">
            <MoroccoExperienceSection>
              <ContentWrapper className="px-lg-5">
              <GradientBackground
                className="gradient-background"
                style={{ height: "auto", width: "100%" }}
                radius="30px">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6">


                      <PackageImageWrapper>
                        <img src={PackageTwo} alt="Package 2" className="mrc-desktop pattern-2" />
                      </PackageImageWrapper>
                    </div>
                    <div className="col-lg-6">

                      <PackageTextWrapper className="mrc-padding">
                        <div className="mb-lg-5 pb-lg-5 mx-lg-5 mt-lg-3">
                          <img src={PackageNumberTwo} className="pb-lg-4 mb-lg-4 mrc-desktop" alt="package two"/>
                          <div className="xrd-reduce-margin-0">
                          <img src={PackageTwoMobile} className="pb-lg-4 mb-lg-4 mrc-mobile" alt="package one"/>
                          </div>
                          <PackageTitle className="pb-lg-3 mb-lg-3 mrc-desktop">Comfort</PackageTitle>
                          <p className="x-font-size-2 x-text pb-lg-1">
                            The perfect balance of comfort and cost! Standard flights, mid-range hotels, and dining for a smooth fan experience.
                          </p>

                          <div className="pt-lg-5 mrc-button-spacer pb-5">
                            <GradientButton className="mrcn-button" href="https://join.myxeno.com/?country=UG&goal=23">Get Started Today</GradientButton>
                          </div>
                        </div>
                      </PackageTextWrapper>
                    </div>
                  </div>
                </div>
              </GradientBackground>
              </ContentWrapper>

            </MoroccoExperienceSection>
        </div>

  );
};

export default ClientMoroccoExperiencePackage2;
