import React from "react";
import styled from "styled-components";
import HeroBkg from "../../images/tulumbe/slice2.png";

import "./blocks/image-card.css";

import "bootstrap/dist/css/bootstrap.min.css";
import ImageSlider from "./blocks/image-slider"

const HeroWrapper = styled.div`
    background: url(${HeroBkg}) no-repeat center center/cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%; /* Adjust this value to control how much of the bottom is covered */
        z-index: 1;
        pointer-events: none;
        background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 0, 0, 0) 100%
        );
    }
`;

const Content = styled.div`
    margin-top: 4rem;
    margin-bottom: 4rem;
`;

const VisitMoroccoSection = styled.div`
  margin-top: 0rem;
  text-align: center;
  color: white;
    position: relative;
    z-index: 2;
    padding-top: 0;
    .btn-lg {
        --bs-btn-padding-y: 0.5rem;
        --bs-btn-padding-x: 1rem;
        --bs-btn-font-size: 1rem;
        --bs-btn-border-radius: var(--bs-border-radius-lg);
    }
`;

const VisitMoroccoTitle = styled.h2`
  font-size: 75px;
  font-weight: bold;
    background: linear-gradient(
            90deg,
            #f4eeba 0%,
            #ebedba 5%,
            #d4ecba 12%,
            #afeabb 21%,
            #7ce8bd 31%,
            #3be4bf 42%,
            #00e2c1 52%,
            #83eb9e 100%
    );
    background-clip: text;
    line-height: 1;
    color: transparent;
    margin-bottom: 47px;
`;

const VisitMorocco = () => {

  return (

<HeroWrapper>
        <div className="container text-white mrc-desktop">
          <Content>
            <VisitMoroccoSection>
              <VisitMoroccoTitle>Visit Morocco,<br/> Cheer the Cranes!</VisitMoroccoTitle>
              <ImageSlider/>

            </VisitMoroccoSection>
          </Content>
        </div>

  <div className="container-fluid text-white mrc-mobile">
    <Content className="mrc-xcontainer-0">
      <VisitMoroccoSection className="mb-3">
        <VisitMoroccoTitle className="xn-h2 mt-4">Visit Morocco,<br/> Cheer the Cranes!</VisitMoroccoTitle>
        <ImageSlider/>

      </VisitMoroccoSection>
    </Content>
  </div>
</HeroWrapper>

  );
};

export default VisitMorocco;
